// CalibrationTab.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, Row, Col,Select,Button, Tooltip, Switch,Collapse } from 'antd';
import OffsetField from '../ResetAQM05/components/OffsetField';
import {ReactComponent as Info} from '../../assets/images/circle-info-solid.svg'
import { PlusOutlined,MinusOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const CalibrationTab = (props) => {
  const {Option} = Select
  const generalState = useSelector((state) => state.reset);
  const dispatch = useDispatch();
  const min= generateArray()
function generateArray() {
  let arr = [];
  for (let i = 1; i <= 1440; i++) {
    arr.push(i.toString().padStart(4, '0'));
  }
  return arr;
}
  // const handleFieldChange = (field, value) => {
  //   dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  // };
const handleFieldChange = (field, value, type, length) => {
  if (field === "region") {
    dispatch({ type: `UPDATE_LORWAN_rx2DataRate`, payload: "" });
    dispatch({ type: `UPDATE_LORWAN_rxChannel`, payload: "" });
  }

  let hex = new RegExp(`^[0-9A-Fa-f]{1,${length}}$`); // Dynamic length for hex
  let no = new RegExp(`^\\d{1,${length}}$`);  // Dynamic length for numbers
  let noString = new RegExp(`^-?\\d{1,${length}}$`);  // Dynamic length for noString
  
  if ((type === "hex" && hex.test(value) && value.length <= length) || (value.length==0) ) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "number" && no.test(value) && value.toString().length <= length )|| (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "noString" && noString.test(value) && value.length <= length) || (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  }
  
};


   const handleIncrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) + inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }
    
    console.log("newVal",newVal,length,lesserLength,newVal.length +1)

    
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: newVal });
  };

  const handleDecrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) - inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }

        dispatch({ type: `UPDATE_LORWAN_${field}`, payload:newVal});
  };



  return (
    <Form style={{width:"100%"}}>
<Collapse accordion defaultActiveKey={"lorwan"}
        expandIcon={({ isActive }) => isActive?<MinusOutlined />:<PlusOutlined />}
        
        className='collapseNested'
      >
      <Panel header="CO2 Sensor" key="lorwan">

      
      <Row gutter={16} className=''>


        <Col span={24}>

          <Form.Item label="Calibration Type" className='settingfield'>
            <Select
              value={generalState.co2Calibration}
              onChange={(e) => handleFieldChange('co2Calibration', e,'number',2)}
              status={props.error && props.error['co2Calibration']?"error":""}
              disabled={props.mode && props.mode=="disabled"?true:false}
              showSearch
            >
               <Option value={'01'} key={"Calibration3"}>Background Calibration</Option>
               <Option value={'02'} key={"Calibration2"}>Target Calibration</Option>
               {/* <Option value={'02'} key={"Calibration2"}>Factory Calibration</Option> */}
             </Select>

          </Form.Item>
        </Col>

        {generalState.co2Calibration && generalState.co2Calibration==="02" &&(
         <OffsetField
          label="Co2 Calibration Value"
          field="co2CalibrationValue"
          resolution={1}
          range={[0, 9999]}
          format={'0000'}
          charLength={4}
          mode={props.mode && props.mode=="disabled"?true:false}
          error={props.error}
      />) }

      </Row>          
        </Panel>
      </Collapse>





    </Form>
  );
};

export default CalibrationTab;
