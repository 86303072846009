import React,{useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Modal, Row,Col, Select } from 'antd';
import LorwanTab from './LorwanTab';
import GeneralTab from './GeneralTab';
import { PlusOutlined,MinusOutlined } from '@ant-design/icons';
import { ActionsContext } from '../../contexts/context';
import WriteLanding from  '../Scanner/Writer/index'
import { Input, Form } from 'antd';
import { useNavigate } from "react-router-dom";
import NetworkSetting from "./NetworkSetting";
import CalibrationSetting from "./CalibrationSetting";
import OffsetSetting from "./OffsetSetting";
import { updateDeviceOffset } from "../SettingsAQM05/actionMethods";
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';


const { Panel } = Collapse;

function ResetAQM05() {
  const {Option} = Select;
  const navigate=useNavigate()
  
  const lorwanState = useSelector((state) => state.reset);
  const settingState = useSelector((state)=>state.lorwan)

  const dispatch = useDispatch();
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};
  const [valueData,SetValueData] =useState({})
  const actionsValue = {actions,setActions};
  const [nfcSettingData,SetNfCSetting] = useState({});
  const [nfcScan,SetNFcScan]= useState([])
  const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [successModalVisibleError, setSuccessModalVisibleError] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [finalValue,SetFinalValue] = useState();
  const [title,SetTitle] =useState();
  const [thirdModal,SetThirdModal]= useState(false);
  const [modalTitle,SetModalTitle]= useState(false);
  const [error,SetError]= useState({});
  const [resetModal,SetResetModal]= useState(false);
  const [loader,SetLoader]= useState(false);
  const [resetVal,SetResetVal] = useState('')
  const savedTemplate = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
  const [listTemplate,SetListTemplate] = useState([
    ...savedTemplate,{id:100000,name:"Custom",type:"default",value:"custom"}
  ]);
  const [titleMessage,SetTitleMessage] = useState('');
  const [errorModal,SetErrorModal]= useState(false);
  

  
  const [selectTemplate,setTemplateSelected] = useState('')

  const handleLorwanFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_RESET_${field.toUpperCase()}`, payload: value });
  };
   let sequnece = [
    { id: 1, key: "deviceEui", required: false, validation: "hex", length: 16 },
    { id: 2, key: "appEui", required: false, validation: "hex", length: 32 },
    { id: 3, key: "applicationKey", required: true, validation: "hex", length: 32 },
    { id: 4, key: "appPort", required: true, validation: "number", length: 3 },
    { id: 5, key: "joinType", required: true, validation: "number", length: 1 },
    { id: 6, key: "networkSessionKey", required: false, validation: "hex", length: 32 },
    { id: 7, key: "applicationSessionKey", required: false, validation: "hex", length: 32 },
    { id: 8, key: "workMode", required: true, validation: "number", length: 1 },
    { id: 9, key: "region", required: true, validation: "number", length: 1 },
    { id: 10, key: "rx2DataRate", required: true, validation: "number", length: 2 },
    { id: 11, key: "rxChannel", required: false, validation: "number", length: 2 },
    { id: 12, key: "spreadingFactor", required: true, validation: "number", length: 2 },
    { id: 13, key: "txPower", required: true, validation: "number", length: 2 },
    { id: 14, key: "adrmode", required: true, validation: "number", length: 1 },
    { id: 15, key: "reportingInterval", required: true, validation: "number", length: 4 },
    { id: 16, key: "confirmed_mode", required: true, validation: "number", length: 1 },
    { id: 17, key: "confirmed_mode_retries", required: true, validation: "number", length: 1 },
    { id: 18, key: "data_storage", required: true, validation: "number", length: 1 },
    { id: 19, key: "data_retransmission", required: true, validation: "number", length: 1 },
    { id: 20, key: "offsetTemp", required: true, validation: "numberString", length: 5 },
    { id: 21, key: "offsetHumidity", required: true, validation: "numberString", length: 3 },
    { id: 22, key: "offsetCo2", required: true, validation: "numberString", length: 5 },
    { id: 23, key: "model_number", required: false, validation: "string", length: 5 },
    { id: 24, key: "firm_ver_number", required: false, validation: "string", length: 5 },
    { id: 25, key: "lora_ver_number", required: false, validation: "string", length: 5 },
    { id: 26, key: "rssi", required: false, validation: "numberString", length: 5 },
    { id: 27, key: "sync_timestamp", required: false, validation: "number", length: 10 },
    { id: 28, key: "reading_timestamp", required: false, validation: "number", length: 10 },
    { id: 29, key: "battery", required: false, validation: "numberString", length: 2 },
    { id: 30, key: "temp", required: false, validation: "numberString", length: 5 },
    { id: 31, key: "humidity", required: false, validation: "numberString", length: 5 },
    { id: 32, key: "co2", required: false, validation: "numberString", length: 5 },
    { id: 33, key: "ssid", required: true, validation: "string", length: 35 },
    { id: 34, key: "password", required: true, validation: "string", length: 35 },
    { id: 35, key: "pm1Offset", required: false, validation: "numberString", length: 2 },
    { id: 36, key: "pm2_5Offset", required: false, validation: "numberString", length: 2 },
    { id: 37, key: "pm10Offset", required: false, validation: "numberString", length: 2 },
    { id: 38, key: "pressureOffset", required: false, validation: "numberString", length: 2 },
    { id: 39, key: "coOffset", required: false, validation: "numberString", length: 7 },
    { id: 40, key: "coRo", required: false, validation: "numberString", length: 7 },
    { id: 41, key: "no2Offset", required: false, validation: "numberString", length: 7 },
    { id: 42, key: "no2Ro", required: false, validation: "numberString", length: 7 },
    { id: 43, key: "o3Offset", required: false, validation: "numberString", length: 7 },
    { id: 44, key: "tvocOffset", required: false, validation: "numberString", length: 7 },
    { id: 45, key: "organizationId", required: false, validation: "string", length: 5 },
    { id: 46, key: "nh3Offset", required: false, validation: "numberString", length: 7 },
    { id: 47, key: "nh3Ro", required: false, validation: "numberString", length: 7 },
    { id: 48, key: "noiseOffset", required: false, validation: "numberString", length: 2 },
    { id: 49, key: "luxOffset", required: false, validation: "numberString", length: 3 },
    { id: 50, key: "occupancyOffset", required: false, validation: "numberString", length: 10 },
    { id: 51, key: "pressure", required: false, validation: "numberString", length: 4 },
    { id: 52, key: "pm1", required: false, validation: "numberString", length: 3 },
    { id: 53, key: "pm2_5", required: false, validation: "numberString", length: 3 },
    { id: 54, key: "pm10", required: false, validation: "numberString", length: 3 },
    { id: 55, key: "tvoc", required: false, validation: "numberString", length: 7 },
    { id: 56, key: "no2", required: false, validation: "numberString", length: 7 },
    { id: 57, key: "co", required: false, validation: "numberString", length: 7 },
    { id: 58, key: "o3", required: false, validation: "numberString", length: 7 },
    { id: 59, key: "nh3", required: false, validation: "numberString", length: 7 },
    { id: 60, key: "audioDba", required: false, validation: "numberString", length: 3 },
    { id: 61, key: "audioPeakDba", required: false, validation: "numberString", length: 3 },
    { id: 62, key: "occupancyStatus", required: false, validation: "string", length: 10 },
    { id: 63, key: "light", required: false, validation: "numberString", length: 5 },
    { id: 64, key: "espFwVersion", required: false, validation: "string", length: 7 },
    { id: 65, key: "communicationMode", required: true, validation: "number", length: 2 },
    { id: 66, key: "peopleCount", required: false, validation: "number", length: 2 },
     { id: 67, key: "calibrationStatus", required: false, validation: "number", length: 1 },
    { id: 68, key: "co2Calibration", required: false, validation: "number", length: 2 },
    { id: 69, key: "co2CalibrationValue", required: false, validation: "number", length: 4 },
  ];
    const [successModalVisibleWarn, setSuccessModalVisibleWarn] = useState(false);
let userDetails=  localStorage.getItem('userLoggedinSafeAirConfig')?JSON.parse(localStorage.getItem('userLoggedinSafeAirConfig')):{};
    useEffect(() => {
    // Set up the message event listener to handle messages from the native side
    const handleMessage = (event) => {
      const message = event.data;
      if (message && message.type === 'nfcWriteSuccess') {
        handleWriteNFC(finalValue)
      }
       if(message && message.type === 'nfcMessage'){
        SetTitleMessage(message.data);
      }
      if (message && message.type === 'nfcScanCancelled') {
         setActions({
              scan: null,
              write: null
            })
      }
            if(message && message.type === 'deviceType'){
        document.body.classlist.add('iosdevice')
      }

    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [finalValue]);

  const handleSuccessClose = () => {
    setSuccessModalVisible(false);
  };

  const handleSave = () => {
    let previousItem = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
    previousItem.push({type:"",name:title,value:finalValue,id:previousItem.length +1})
    localStorage.setItem('nfcTemplate',JSON.stringify(previousItem));
    SetListTemplate((prev)=>[...prev,{type:"",name:title,value:finalValue,id:previousItem.length +1}]);
    SetModalTitle(false);
    setSecondModalVisible(true);    
  };


   const onHandleAction = (actions) =>{
    setActions({...actions});
  }

  function validation(obj,value){
    //console.log("validation",obj,value);
    let length = obj.length;
    let type= obj.validation;
    let field = obj.key;
    let hex = new RegExp(`^[0-9A-Fa-f]{${length}}$`); 
    let no = new RegExp(`^\\d{${length}}$`); 
    let noString = new RegExp(`^-?\\d{${length}}$`); 
    // console.log("field" ,field,obj,value,length,!isNaN(value), value.toString().length <= length )
    if((obj.key =="ssid" || obj.key==="password") && (lorwanState.communicationMode ==="02" || lorwanState.communicationMode ==="") ){
      return true
    }else if ((obj.key =="ssid" || obj.key==="password") && value.length > 0){
      return true
    }
    else if(!obj.required){
      return true
    }
    else if ((type === "hex" && hex.test(value) && value.length <= length)  ) {
      return true
    } else if ((type === "number" && !isNaN(value) && value!=="" && value.toString().length <= length )) {
      
      return true
    }else if(field ==='rxChannel' && value!=="" && value.toString().length <= length){
      
      return true
    } else if ((type === "numberString" && (value.includes("+") || value.includes("-")) && value.length <= length) ) {
      return true
    }else{
      return false
    } 
    
  }
  function savedDataTemplate(typeOperation){
    SetTitle('');
    let gatewaySetting={};
    gatewaySetting=lorwanState;
    let value="";
    let seq=[];
    let errorCount=0;

    const now = new Date();
    const utcTimestamp = parseInt((now.getTime())/1000);
    console.log("default value",lorwanState)
    seq = [...sequnece]  
    let er={}
    for(let i=0;i<seq.length;i++){
      console.log("validation",validation(seq[i],lorwanState[seq[i].key]))
      console.log("valid Field",seq[i].key,lorwanState[seq[i].key])    
      if(!validation(seq[i],lorwanState[seq[i].key])){
        er[seq[i].key] = "Field is required or invalid"
        errorCount++;
      }
      if(i===0){
        value+=seq[i].id+":"+settingState.deviceEui+","
      }
      // else if(seq[i].key === "offsetTemp" ){
      //   value+=seq[i].id+":+00.0,";
      // }
      else if(seq[i].key === "sync_timestamp" ){
        value+=seq[i].id+":"+utcTimestamp+",";
      }
      else if(seq[i].key === "co2CalibrationValue"){
        value+=seq[i].id+":"+lorwanState[seq[i].key];
      }else{
        value+=seq[i].id+":"+lorwanState[seq[i].key]+",";
      }
  
    }
    SetError(er);
    console.log("value",value,er,errorCount);

// SetModalTitle(true);
    if(errorCount==0){
      SetFinalValue(value);
      SetNfCSetting(value);
      //handleWriteNFC(value)
      if(window.ReactNativeWebView){ 
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'writeNFC',data:value }));
      }
       SetResetVal('');
      onHandleAction({scan: null, write: 'scaning'});
      //  handleWriteNFC(value)
    }else{
      SetErrorModal(true);
    }
  }

  function writeSucess(){
       onHandleAction({scan: null, write: null})       
  }




 const handleWriteNFC = async (value) => {
  const keyValuePairs = value.split(',');

// Create an object to store the key-value pairs
const dataObject = {};
keyValuePairs.forEach(pair => {
    const [key, value] = pair.split(':');
    let keyName = sequnece.find(el=>el.id == key);
    
    if(keyName){
      dataObject[keyName.key] = value;
    }
});
if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'dataObject',data:dataObject }));
          }

  let requestBody ={
     "device_serial": dataObject.deviceEui.startsWith('0005')?dataObject.deviceEui.substring(2,dataObject.deviceEui.length):dataObject.deviceEui.startsWith('05')?dataObject.deviceEui:`05${dataObject.deviceEui}`,
    "no2_Ro": dataObject.no2Ro,
    "co_Ro": dataObject.coRo,
    "o3_Ro": "0",
    "nh3_Ro":dataObject.nh3Ro ,
    "temperature": dataObject.offsetTemp,
    "humidity": dataObject.offsetHumidity,
    "co2": dataObject.offsetCo2,
    "o3": dataObject.o3Offset,
    "no2": dataObject.no2Offset,
    "co": dataObject.coOffset,
    "pressure": dataObject.pressureOffset,
    "pm1": dataObject.pm1Offset,
    "pm2_5": dataObject.pm2_5Offset,
    "pm10": dataObject.pm10Offset,
    "nh3": dataObject.nh3Offset,
    "tvoc": dataObject.tvocOffset,
    "noise": dataObject.noiseOffset,
    "lux": dataObject.luxOffset,
    "occupancy": dataObject.occupancyOffset,
    "mode": dataObject.communicationMode,
    "session": 'Safe123456789'
}

SetLoader(false);    

if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'requestbody',data:requestBody }));
          }
SetLoader(true);
    updateDeviceOffset(requestBody).then(res=>{
      if(res.status===200){
        SetLoader(false);
        setSecondModalVisible(true);
        setSuccessModalVisibleWarn(true);
        // setSuccessModalVisible(true);    
        setSuccessModalVisible(true);   
        onHandleAction({scan: null, write: null})       
        if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'apiUpdateResponseIF',data:res }));
          }
      }else {
        // setSuccessModalVisible(true);    
        SetLoader(false);
        setSecondModalVisible(true);
        setSuccessModalVisibleError(true);    
        setSuccessModalVisible(true);     
        onHandleAction({scan: null, write: null})       
        if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'apiUpdateResponseElse',data:res }));
          }
      }
    })



      // onHandleAction({scan: null, write: null})       
};

const handleSecondModalClose = () => {
    setSecondModalVisible(false);
  };


  return (
    <div className="App">
      <div className="container">
                   {loader?<div className="loaderArea">
              <div className="loaderInner">  
                <Spin indicator={<LoadingOutlined style={{ fontSize: 78 }} spin />} />
            </div>
          </div>:""}

       <Collapse accordion defaultActiveKey={"network"}
        expandIcon={({ isActive }) => isActive?<MinusOutlined />:<PlusOutlined />}
        style={{marginTop:20}}
      >

        <Panel header="Communication" key="network">
          <NetworkSetting error={error} mode={'disabled'}/>
        </Panel>
        <Panel header="General" key="general">
          <GeneralTab error={error} mode={'disabled'} />
        </Panel>
        <Panel header="Sensor Calibration" key="calibration">
          <CalibrationSetting error={error} mode={'disabled'} />
        </Panel>
        <Panel header="Sesnor Reading Offset" key="offset">
          <OffsetSetting error={error} mode={'disabled'} />
        </Panel>

      </Collapse>
        <ActionsContext.Provider value={actionsValue}>
          {write && <WriteLanding message={nfcSettingData} writeSucess={writeSucess}  title={titleMessage}/>}
        </ActionsContext.Provider>

        <div>
        </div>

    <div className="App-container-bottom " style={{position:"relative",padding:"15px 0px",marginBottom:15}}>       
          <button className="btn btn  btn-transparent"  onClick={()=>SetResetModal(true)}>Reset</button>
          {/* <button onClick={()=>savedDataTemplate()} style={{marginTop:"10px"}} className="btn btn-transparent">Write</button> */}
        </div>

    </div>

   <Modal
        title="Error"
        open={errorModal}
        onCancel={()=>SetErrorModal(false)}
        footer={[
          <Button key="close" onClick={()=>SetErrorModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className="errorSmaller">
        {Object.entries(error).map(([key, value]) => (
    <Row
      gutter={16}
      style={{ marginBottom: 5, paddingBottom: 5, borderBottom: "1px solid #ddd" }}
      key={key}
    >
      <Col span={8} style={{color:"#a22626",fontSize:10}}>{key}</Col>
      <Col span={16} style={{fontSize:10}}>{value}</Col>
    </Row>
  ))}
        </div>
      </Modal>


    <Modal
        title="Reset"
        open={resetModal}
        onCancel={()=>SetResetModal(false)}
        footer={[
          <Button key="close" onClick={()=>SetResetModal(false)}>
            No, Go back
          </Button>,
          <Button key="save" type="primary" onClick={()=>{SetResetModal(false);savedDataTemplate();}}>
            Yes, Reset
          </Button>,
        ]}
      >
        Are You sure wan't to reset
      </Modal>

        <Modal
        title="Save Template"
        open={modalTitle}
        onCancel={()=>SetModalTitle(false)}

        footer={[
          <Button key="close" onClick={()=>SetModalTitle(false)}>
            Close
          </Button>,
        ]}
      >
    <Form>
      
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className='settingfield' label="Template Title">
            <Input
              value={title}
              onChange={(e) => SetTitle(e.target.value)}
            />
          </Form.Item>
        </Col>
        </Row>
        </Form>       
      </Modal>

    <Modal
        title="Reset Done"
        open={secondModalVisible}
        onCancel={()=>handleSecondModalClose()}
        footer={[
          <Button key="close" onClick={()=>handleSecondModalClose()}>
            Close
          </Button>,
        ]}
      >
        {successModalVisibleError?<span style={{color:"red"}}>Device reset successfully, but data unable to sync with cloud. Please repeat the reset</span> :'Device reset successfully and data sync with cloud.'}
        
      </Modal>
   </div> 
  );
}

export default ResetAQM05;
