import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const OffsetField = ({ label, field, resolution, range, charLength, mode, error,format }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.reset[field]);

  const handleIncrement = () => {
    updateFieldValue(resolution);
  };

  const handleDecrement = () => {
    updateFieldValue(-resolution);
  };

 const updateFieldValue = (change) => {
    // Extract the current numeric value, keeping the sign if present
    let currentValue = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;

    // Update value based on resolution
    let newValue = currentValue + change;

    // Ensure new value is within the specified range
    newValue = Math.max(range[0], Math.min(newValue, range[1]));

    // Determine the formatting details from the `format` prop
    const hasSign = format.includes('+'); // Check if the format requires a sign
    const [integerPart, decimalPart] = format.replace('+', '').split('.');
    const integerDigits = integerPart.length; // Number of digits before the decimal
    const decimalDigits = decimalPart ? decimalPart.length : 0; // Number of digits after the decimal

    // Format the value with appropriate padding and sign
    let formattedValue = Math.abs(newValue)
      .toFixed(decimalDigits) // Set the required number of decimal places
      .padStart(integerDigits + (decimalDigits > 0 ? decimalDigits + 1 : 0), '0'); // Pad integer part

    // Add sign if format requires it and it's not excluded for specific fields
    if (hasSign && !field.includes('Ro') && !field.includes('occupancy')) {
      const sign = newValue < 0 ? '-' : '+';
      formattedValue = `${sign}${formattedValue}`;
    }

    // Dispatch the updated value
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: formattedValue });
  };


  return (
    <Col span={24}>
      <Form.Item className='settingfield backgroundHighlight' label={label}>
        <Row gutter={16}>
          <Col span={6}>
            <Button
              disabled={mode}
              onClick={handleDecrement}
              className='btnChannel'
            >
              -
            </Button>
          </Col>
          <Col span={12}>
            <Input
              value={value}
              className='textCenter'
              status={error && error[field] ? 'error' : ''}
              onChange={(e) => updateFieldValue(parseInt(e.target.value) || 0)}
              disabled={mode}
            />
          </Col>
          <Col span={6}>
            <Button
              disabled={mode}
              onClick={handleIncrement}
              className='btnChannel'
            >
              +
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Col>
  );
};

export default OffsetField;
