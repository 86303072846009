// NetworkSetting.js
import React,{useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, Row, Col,Select,Button,Collapse, AutoComplete } from 'antd';
import { Radio } from 'antd';
import LorwanTab from './LorwanTab';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const { Panel } = Collapse;


const NetworkSetting = (props) => {
  const {Option} = Select
  const generalState = useSelector((state) => state.lorwan);
  const [isCustom, setIsCustom] = useState(false);
  const dispatch = useDispatch();
  const min= generateArray()
    const [visible, setVisible] = useState(false);
function generateArray() {
  let arr = [];
  for (let i = 1; i <= 1440; i++) {
    arr.push(i.toString().padStart(4, '0'));
  }
  return arr;
}
  // const handleFieldChange = (field, value) => {
  //   dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  // };
const handleFieldChange = (field, value, type, length) => {
  console.log("hello",field, value, type, length)
    console.log(field, value, type, length,`UPDATE_LORWAN_${field}`)

  let hex = new RegExp(`^[0-9A-Fa-f]{1,${length}}$`); // Dynamic length for hex
  let no = new RegExp(`^\\d{1,${length}}$`);  // Dynamic length for numbers
  let noString = new RegExp(`^-?\\d{1,${length}}$`);  // Dynamic length for noString
  
  if ((type === "hex" && hex.test(value) && value.length <= length) || (value.length==0) ) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
    console.log("condition 1");
  } else if ((type === "number" && no.test(value) && value.toString().length <= length )|| (value.length==0)) {
    console.log("condition 2");
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "noString" && noString.test(value) && value.length <= length) || (value.length==0)) {
    console.log("condition 3");
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  }else if(type === "string" && value.length <=length){
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  }
  
};
const handleSearch = (field, value, type, length) => {
    handleFieldChange(field, value, type, length)
    setIsCustom(!props.wifiList.find(el=>el.SSID.includes(value)))
  };


   const handleIncrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) + inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }
    
    console.log("newVal",newVal,length,lesserLength,newVal.length +1)

    
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: newVal });
  };

  const handleDecrement = (field,val,type,length) => {
    let inc= 1;
    if(field=="offsetCo2"){
      inc=5
    }
    let finalValue='';
    let newVal= Number(parseFloat(val) - inc);
    let sign = newVal >= 0?"+":"-";
    let lesserLength = length - (Math.abs(newVal).toString().length +1);
    if(lesserLength==4){
      newVal= `${sign}0000${Math.abs(newVal)}`;
    }else if(lesserLength==3){
      newVal= `${sign}000${Math.abs(newVal)}`;
    }else if(lesserLength==2){
      newVal= `${sign}00${Math.abs(newVal)}`;
    }else if(lesserLength==1){
      newVal= `${sign}0${Math.abs(newVal)}`;
    }else{
      newVal = `${sign}${Math.abs(newVal)}`;
    }

        dispatch({ type: `UPDATE_LORWAN_${field}`, payload:newVal});
  };


const handleChange = (field, value, type, length,from) => {
  console.log("handle Change issue",from,field, value, type, length)  
  
handleFieldChange(field, value, type, length)
    
    setIsCustom(false); // Reset custom status if selecting from dropdown
  };


  const toggleVisibility = () => {
    setVisible(!visible);
  };


  return (
    <Form>
      <Row gutter={16} className=''>

        <Col span={24}>
          <Form.Item label="Communication Mode" className='settingfield'>
            <Radio.Group onChange={(e)=>handleFieldChange('communicationMode', e.target.value,'noString',2)} value={generalState.communicationMode}>
              <Radio value={'01'}>Wifi</Radio>
              <Radio value={'02'}>LoRawan</Radio>
            </Radio.Group>

          </Form.Item>
        </Col>

        {generalState.communicationMode && generalState.communicationMode=="02" && (<><h4 className='headerTitleGroup'>Lorawan Settings</h4><LorwanTab error={props.error} /> </>)}
        {generalState.communicationMode && generalState.communicationMode=="01"?<><h4 className='headerTitleGroup'>Wifi Settings</h4>
        <Col span={24}>
    
          <Form.Item className='settingfield' label="SSID">
      <Row gutter={16}>
          <Col span={24}>

{/* <Select
  style={{ width: "100%", height: "40px" }}
  placeholder="Select or type"
  value={generalState.ssid || undefined}
  onFocus={() => handleChange("ssid", generalState.ssid, "string", 30, "onfocus")}
  onChange={(value) => handleChange("ssid", value, "string", 30, "onchange")}
  showSearch
  onSearch={(value) => handleChange("ssid", value, "string", 30, "onsearch")} // Update state on typing
  optionFilterProp="children"
  filterOption={(input, option) =>
    option?.children?.toLowerCase().includes(input.toLowerCase())
  }
  notFoundContent={null}
>
  {props.wifiList.map((option) => (
    <Option key={option.SSID} value={option.SSID}>
      {option.SSID}
    </Option>
  ))}
  {generalState.ssid &&
    !props.wifiList.some((option) => option.SSID === generalState.ssid) && (
      <Option key="custom" value={generalState.ssid}>
        {generalState.ssid}
      </Option>
    )}
</Select> */}
 <AutoComplete
      style={{ width: "100%", height: "40px" }}
      placeholder="Select or type"
      value={generalState.ssid}
      onChange={(value)=>handleChange("ssid", value, "string", 30)}
      options={props.wifiList.map((option) => ({
        value: option.SSID,
        label: option.SSID,
      }))}
      filterOption={(input, option) =>
        option.value.toLowerCase().includes(input.toLowerCase())
      }
      allowClear
    />

            {/* <Input
              value={generalState.ssid}
              className=''
              status={props.error && props.error['ssid']?"error":""}
              onChange={(e) => handleFieldChange('ssid', e.target.value,'string',32)}
              disabled={props.mode && props.mode=="disabled"?true:false}
            /> */}
          </Col>
        </Row>
    </Form.Item>


        </Col>
                <Col span={24}>
    
          <Form.Item className='settingfield' label="Password">

      <Row gutter={16}>
          <Col span={24}>
            {/* <Input
              value={generalState.password}
              className=''
              status={props.error && props.error['password']?"error":""}
              onChange={(e) => handleFieldChange('password', e.target.value,'string',32)}
              disabled={props.mode && props.mode=="disabled"?true:false}
              type={}
            /> */}
        <Input.Password
          placeholder="Enter your password"
          visibilityToggle={{ onClick: toggleVisibility }} // Custom visibility toggle
       
           iconRender={visible => (visible ? <EyeOutlined onClick={toggleVisibility} /> : <EyeInvisibleOutlined onClick={toggleVisibility} />)}
          value={generalState.password}
          status={props.error && props.error['password']?"error":""}
          onChange={(e) => handleFieldChange('password', e.target.value,'string',30)}
          disabled={props.mode && props.mode=="disabled"?true:false}
        />

          </Col>
        </Row>
    </Form.Item>


        </Col></>:null}

      </Row>


    </Form>
  );
};

export default NetworkSetting;
